import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Seo from '../components/SeoComponent';
import Layout from '../components/LayoutComponent';
import ArticlePreview from '../components/ArticlePreview';
import sanitizeHtml from 'sanitize-html';
//import Banner from '../components/supportedPhones/Banner';
import Breadcrumbs from '../components/common/Breadcrumbs';
//import Phones from '../components/supportedPhones/Phones';

// const domainUrl = process.env.GATSBY_DOMAIN_URL || '';
const domainUrl = '';

class BrandPostTemplate extends React.Component {
    render() {
        const posts = get(this.props, 'data.allposts.nodes');
        const brands = get(this.props, 'data.brands.nodes');
        const metaTagsApple = get(this.props, 'data.metaTagsApple');
        const metaTagsSamsung = get(this.props, 'data.metaTagsSamsung');
        const metaTagsOppo = get(this.props, 'data.metaTagsOppo');
        const breadCrumbs = get(this.props, 'pageContext.breadCrumbs');

        console.log(breadCrumbs);

        const slug = this.props && this.props.pageContext.slug ? sanitizeHtml(this.props.pageContext.slug) : '';
        const canonicalUrl =
            this.props && this.props.pageContext && this.props.pageContext.canonicalPath
                ? domainUrl + this.props.pageContext.canonicalPath
                : domainUrl + this.props.path;
        const location = this.props && this.props.location ? sanitizeHtml(this.props.location) : '';
        var metaTitle = '';
        var metaDescription = '';
        var ogImage = '';

        console.log(slug);
        if (slug === 'Apple') {
            // console.log("a")
            metaTitle =
                metaTagsApple && metaTagsApple.title
                    ? sanitizeHtml(metaTagsApple.title)
                    : 'Supported Phones | Asurion Mobile+';
            metaDescription =
                metaTagsApple && metaTagsApple.description
                    ? sanitizeHtml(metaTagsApple.description)
                    : 'The full list of phones eligible for Asurion Mobile+ smartphone insurance. We provide cover for most Apple and Samsung smartphones up to 48 months old.';
            ogImage = metaTagsApple && metaTagsApple.ogImage ? metaTagsApple.ogImage.file.url : '';
        } else if (slug === 'Oppo') {
            metaTitle =
                metaTagsOppo && metaTagsOppo.title
                    ? sanitizeHtml(metaTagsOppo.title)
                    : 'Supported Phones | Asurion Mobile+';
            metaDescription =
                metaTagsOppo && metaTagsOppo.description
                    ? sanitizeHtml(metaTagsOppo.description)
                    : 'The full list of phones eligible for Asurion Mobile+ smartphone insurance. We provide cover for most Apple and Samsung smartphones up to 48 months old.';
            ogImage = metaTagsOppo && metaTagsOppo.ogImage ? metaTagsOppo.ogImage.file.url : '';
        } else {
            //console.log('s')
            metaTitle =
                metaTagsSamsung && metaTagsSamsung.title
                    ? sanitizeHtml(metaTagsSamsung.title)
                    : 'Supported Phones | Asurion Mobile+';
            metaDescription =
                metaTagsSamsung && metaTagsSamsung.description
                    ? sanitizeHtml(metaTagsSamsung.description)
                    : 'The full list of phones eligible for Asurion Mobile+ smartphone insurance. We provide cover for most Apple and Samsung smartphones up to 48 months old.';
            ogImage = metaTagsSamsung && metaTagsSamsung.ogImage ? metaTagsSamsung.ogImage.file.url : '';
        }

        //console.log(metaDescription)
        //const posts = get(this, 'props.data.allContentfulDeviceDetailPage.nodes')

        console.log('canonical', canonicalUrl);

        return (
            <Layout
                location={location}
                subcomponent={
                    <>
                        <Seo
                            title={metaTitle}
                            description={metaDescription}
                            image={ogImage}
                            ogImage={ogImage}
                            canonical={canonicalUrl}
                        />
                        {/* <Breadcrumbs breadCrumbs={breadCrumbs} /> */}
                        {/* <Banner></Banner> */}
                        <ArticlePreview posts={posts} brands={brands} activeBrand={slug} />
                        {/* <Phones posts={posts} brands={brands} activeBrand={slug} />  */}
                    </>
                }
            />
        );
    }
}

export default BrandPostTemplate;

export const pageQuery = graphql`
    query BrandQuery($slug: String) {
        allposts: allContentfulDeviceDetailPage(
            sort: { fields: launchDate, order: DESC }
            filter: { brandName: { eq: $slug }, node_locale: { eq: "en-US" } }
        ) {
            nodes {
                brand
                brandName
                updatedAt
                title
                slug
                reviewVideo {
                    file {
                        url
                    }
                    gatsbyImageData(layout: FIXED, resizingBehavior: SCALE, width: 65)
                }
                variants
            }
        }
        brands: allContentfulDeviceDetailPage(filter: { brandName: {} }) {
            nodes {
                brandName
            }
        }
        metaTagsApple: contentfulMetaTags(title: { eq: "Supported Apple Phones | Asurion Mobile+" }) {
            title
            robots
            canonical
            description
            ogImage {
                file {
                    url
                    fileName
                }
            }
        }
        metaTagsSamsung: contentfulMetaTags(title: { eq: "Supported Samsung Phones | Asurion Mobile+" }) {
            title
            robots
            canonical
            description
            ogImage {
                file {
                    url
                    fileName
                }
            }
        }
        metaTagsOppo: contentfulMetaTags(title: { eq: "Supported Oppo Phones | Asurion Mobile+" }) {
            title
            robots
            canonical
            description
            ogImage {
                file {
                    url
                    fileName
                }
            }
        }
    }
`;
